import React from 'react';
import { Button, ButtonProps, useMediaQuery, useTheme } from '@mui/material';
import { Search as SearchIcon } from '@mui/icons-material';
import { useIntl } from '@kamernet/core/Intl';
import { useRouter } from 'next/router';
import styles from './SubmitButton.module.css';

export const SubmitButton = (props: ButtonProps) => {
  const { T } = useIntl();
  const theme = useTheme();
  const router = useRouter();
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));
  const startIcon = React.useMemo(() => !isSmUp && <SearchIcon />, [isSmUp]);

  return (
    <Button
      variant="contained"
      color={router.query.typeAndCity ? 'secondary' : 'primary'}
      type="submit"
      size="medium"
      startIcon={startIcon}
      className={styles.root}
      data-testid="submit-button"
      {...props}
    >
      {isSmUp && T('LBL_SEARCH')}
    </Button>
  );
};
