import React from 'react';

import { ListingRequest } from '@kamernet/core/ApiClient/Kamernet';
import { AuthContext } from '@kamernet/core/Auth/AuthContext';
import { useLocalStorage } from '@kamernet/core/BrowserStorage';

import { SearchView } from '../types';

export const useStoreAnonymousPSA = (
  initialListingRequest?: ListingRequest | null,
) => {
  const { isAuthenticated } = AuthContext.useContext();
  const [anonymousPSA, setAnonymousPSA, isAnonymousPSAReady] =
    useLocalStorage('kn-anonymous-psa');

  const storeAnonymousPSA = React.useCallback(
    (newListingRequest: ListingRequest): void => {
      if (
        !newListingRequest ||
        newListingRequest.searchview !== SearchView.List
      ) {
        return;
      }

      if (isAuthenticated || !newListingRequest.location) {
        return;
      }

      const listingRequests = anonymousPSA?.listingRequests || [];

      // Find index of the existing request with the same location
      const existingIndex = listingRequests.findIndex(
        req => req.location?.cityName === newListingRequest.location?.cityName,
      );

      // If found, remove the old entry
      if (existingIndex !== -1) {
        listingRequests.splice(existingIndex, 1);
      }

      listingRequests.push(newListingRequest);

      if (listingRequests.length > 3) {
        // Remove the oldest request (first in the array)
        listingRequests.shift();
      }

      setAnonymousPSA({ listingRequests });
    },
    [isAuthenticated, anonymousPSA, setAnonymousPSA],
  );

  React.useEffect(() => {
    if (!initialListingRequest || !isAnonymousPSAReady) {
      return;
    }

    storeAnonymousPSA(initialListingRequest);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAnonymousPSAReady]);

  return { storeAnonymousPSA };
};
