import { Filter, IListingsState, ListingsResult } from './ListingsContext';
import {
  FilterActionTypes,
  FilterActions,
  FiltersList,
  Listings,
  ListingActionTypes,
  ListingActions,
} from './types';

export const filterReducer = (state: Filter, action: FilterActions): Filter => {
  switch (action.type) {
    case FilterActionTypes.UPDATE_FILTER:
      return {
        ...state,
        filters: action.payload as unknown as FiltersList,
      };
    case FilterActionTypes.UPDATE_FILTER_COUNT:
      return {
        ...state,
        count: action.payload as unknown as number,
      };
    case FilterActionTypes.CLEAR_FILTERS:
      return { ...state, filters: {} as FiltersList };

    default:
      return state;
  }
};

export const listingReducer = (
  state: ListingsResult,
  action: ListingActions,
): ListingsResult => {
  switch (action.type) {
    case ListingActionTypes.UPDATE_LISTING:
      return {
        ...state,
        listings: action.payload as unknown as Listings,
      };
    case ListingActionTypes.UPDATE_LISTING_LOADING_STATE:
      return {
        ...state,
        isLoading: action.payload as unknown as boolean,
      };
    default:
      return state;
  }
};

export const mainReducer = (
  { filterResult, listingsResult }: IListingsState,
  action: ListingActions | FilterActions,
) => ({
  filterResult: filterReducer(filterResult, action as FilterActions),
  listingsResult: listingReducer(listingsResult, action as ListingActions),
});
