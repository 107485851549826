import React from 'react';
import {
  FormControl,
  Typography,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { RadiusIDLabels } from '@kamernet/modules/Listing/messages';
import { RadiusIDValues } from '@kamernet/modules/Listing/types';
import styles from './RadiusInput.module.css';

export interface RadiusInputProps {
  value: number;
  onChange: (value: number) => void;
}

export const RadiusInput = ({ value, onChange }: RadiusInputProps) => {
  const onChangeInternal = React.useCallback(
    (event: SelectChangeEvent<number>) => {
      onChange(event.target.value as number);
    },
    [onChange],
  );

  const options = React.useMemo(() => {
    return RadiusIDValues.map(radius => (
      <MenuItem key={radius} value={radius}>
        <ListItemText>
          <Typography variant="body3">{RadiusIDLabels[radius]}</Typography>
        </ListItemText>
      </MenuItem>
    ));
  }, []);

  return (
    <FormControl className={styles.root}>
      <Select
        className={styles.select}
        value={value}
        autoWidth
        size="small"
        data-testid="radius-input"
        onChange={onChangeInternal}
      >
        {options}
      </Select>
    </FormControl>
  );
};
