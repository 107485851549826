import { defineKey } from '@ha/intl';

import {
  OwnerTypeID,
  RoomAdvertSortOptionID,
  RoomTypeID,
  FacilitiesUsage,
  GenderEnum,
  SuitableForNumberID,
  Statuses,
  Furnishings,
  RoommateNumberID,
  RoomAbuseType,
  LanguageID,
  InternetFacilityUsage,
  EneryLabelFacilityUsage,
} from '@kamernet/core/ApiClient/Kamernet';

import { ListingChipLabels } from './types';

export const RoomTypeIDTranslations: Record<RoomTypeID, string> = {
  [RoomTypeID.Room]: defineKey('DMN_ROOM'),
  [RoomTypeID.Apartment]: defineKey('DMN_APARTMENT'),
  [RoomTypeID.Studio]: defineKey('DMN_STUDIO'),
  [RoomTypeID.Antikraak]: defineKey('DMN_ANTI_SQUAT'),
  [RoomTypeID.StudentResidence]: defineKey('LBL_STUDENT_RESIDENCE'),
};

export const RoomTypeIDPluralTranslations: Record<RoomTypeID, string> = {
  [RoomTypeID.Room]: defineKey('LBL_ROOMS'),
  [RoomTypeID.Apartment]: defineKey('LBL_APARTMENTS'),
  [RoomTypeID.Studio]: defineKey('LBL_STUDIOS'),
  [RoomTypeID.Antikraak]: defineKey('LBL_ANTISQUATS'),
  [RoomTypeID.StudentResidence]: defineKey('DMN_STUDENT_RESIDENCE'),
};

export const RoomAdvertSortOptionIDTranslations: Record<
  RoomAdvertSortOptionID,
  string
> = {
  [RoomAdvertSortOptionID.NewestFirst]: defineKey('DMN_SORT_NEWEST_FIRST'),
  [RoomAdvertSortOptionID.PriceAscending]: defineKey('sorting.lowest_price'),
  [RoomAdvertSortOptionID.PriceDescending]: defineKey('sorting.highest_price'),
  [RoomAdvertSortOptionID.Surface]: defineKey('DMN_SORT_SURFACE'),
  [RoomAdvertSortOptionID.Availability]: defineKey(
    'sorting.soonest_availability',
  ),
  [RoomAdvertSortOptionID.SocialConnectionsFirst]: '',
};

export const ListingChipLabelTranslations: Record<ListingChipLabels, string> = {
  [ListingChipLabels.New]: defineKey('label.new'),
  [ListingChipLabels.TopAd]: defineKey('LBL_RD_TOP_ADVERTISEMENT_TAG'),
  [ListingChipLabels.ReactForFree]: defineKey(
    'LBL_STEP4_CREDITS_REACT_FOR_FREE',
  ),
  [ListingChipLabels.StillAvailable]: defineKey('LBL_MOVEDUP_ADVERTISEMENT'),
};

export const FacilitesLivingRoomTranslations: Record<FacilitiesUsage, string> =
  {
    [FacilitiesUsage.None]: defineKey('ldp.what_you_get.no_living_room'),
    [FacilitiesUsage.Own]: defineKey('ldp.what_you_get.own_livingroom'),
    [FacilitiesUsage.Shared]: defineKey('ldp.what_you_get.shared_living_room'),
  };

export const FacilitesKitchenTranslations: Record<FacilitiesUsage, string> = {
  [FacilitiesUsage.None]: defineKey('ldp.what_you_get.no_kitchen'),
  [FacilitiesUsage.Own]: defineKey('ldp.what_you_get.private_kitchen'),
  [FacilitiesUsage.Shared]: defineKey('ldp.what_you_get.shared_kitchen'),
};

export const FacilitesToiletTranslations: Record<FacilitiesUsage, string> = {
  [FacilitiesUsage.None]: defineKey('ldp.what_you_get.no_toilet'),
  [FacilitiesUsage.Own]: defineKey('ldp.what_you_get.private_toilet'),
  [FacilitiesUsage.Shared]: defineKey('ldp.what_you_get.shared_toilet'),
};

export const FacilitesShowerTranslations: Record<FacilitiesUsage, string> = {
  [FacilitiesUsage.None]: defineKey('ldp.what_you_get.no_bathroom'),
  [FacilitiesUsage.Own]: defineKey('ldp.what_you_get.own_bathroom'),
  [FacilitiesUsage.Shared]: defineKey('ldp.what_you_get.shared_bathroom'),
};

export const FacilitesInternetTranslations: Record<
  InternetFacilityUsage,
  string
> = {
  [InternetFacilityUsage.Available]: defineKey(
    'ldp.what_you_get.internet_available',
  ),
  [InternetFacilityUsage.NotAvailable]: defineKey(
    'ldp.what_you_get.no_internet',
  ),
  [InternetFacilityUsage.AdditionalCharge]: defineKey(
    'ldp.what_you_get.internet_for_fee',
  ),
  [InternetFacilityUsage.Unimportant]: defineKey(
    'ldp.what_you_get.internet_unimportant',
  ),
};

export const FacilitesEnergyLabelTranslations: Record<
  EneryLabelFacilityUsage,
  string
> = {
  [EneryLabelFacilityUsage.Unknonwn]: defineKey(
    'ldp.what_you_get.unknown_energy_label',
  ),
  [EneryLabelFacilityUsage.A]: 'A',
  [EneryLabelFacilityUsage.B]: 'B',
  [EneryLabelFacilityUsage.C]: 'C',
  [EneryLabelFacilityUsage.D]: 'D',
  [EneryLabelFacilityUsage.E]: 'E',
  [EneryLabelFacilityUsage.F]: 'F',
  [EneryLabelFacilityUsage.G]: 'G',
};

export const OwnertypeLabelTranslations: Record<OwnerTypeID, string> = {
  [OwnerTypeID.PrivateOwner]: defineKey('DMN_PRIVATE_OWNER'),
  [OwnerTypeID.Roommate]: defineKey('DMN_ROOMMATE'),
  [OwnerTypeID.RealEstateAgent]: defineKey('DMN_REAL_ESTATE_AGENT'),
  [OwnerTypeID.Other]: defineKey('LBL_OTHER'),
};

export const GenderLabelTranslations: Record<GenderEnum, string> = {
  [GenderEnum.Male]: defineKey('DMN_MALE'),
  [GenderEnum.Female]: defineKey('DMN_FEMALE'),
  [GenderEnum.Mixed]: defineKey('DMN_MIXED'),
  [GenderEnum.NotImportant]: defineKey('DMN_NOT_IMPORTANT'),
};

export const RoommatesGenderLabelTranslations: Record<GenderEnum, string> = {
  [GenderEnum.Male]: defineKey('ldp.what_you_get.male_roommates'),
  [GenderEnum.Female]: defineKey('ldp.what_you_get.female_roommates'),
  [GenderEnum.Mixed]: defineKey('ldp.what_you_get.mixed_gender_roommates'),
  [GenderEnum.NotImportant]: defineKey('DMN_NOT_IMPORTANT'),
};

export const SuitableForNumberofPersonTranslations: Record<
  SuitableForNumberID,
  string
> = {
  [SuitableForNumberID.OnePerson]: '1',
  [SuitableForNumberID.TwoPersons]: '2',
  [SuitableForNumberID.ThreePersons]: '3',
  [SuitableForNumberID.FourPersons]: '4',
  [SuitableForNumberID.FivePersons]: '5',
  [SuitableForNumberID.MoreThanFivePersons]: defineKey('5+'),
};

export const WorkStatusLabelTranslations: Record<Statuses, string> = {
  [Statuses.Student]: defineKey('DMN_STUDENT'),
  [Statuses.Working]: defineKey('DMN_WORKING'),
  [Statuses.WorkingStudent]: defineKey('DMN_WORKING_STUDENT'),
  [Statuses.LookingForJob]: defineKey('DMN_LOOKING_FOR_JOB'),
};

export const LanguageLabelTranslations: Record<LanguageID, string> = {
  [LanguageID.DUTCH]: defineKey('DMN_DUTCH'),
  [LanguageID.ARABIC]: defineKey('DMN_ARABIC'),
  [LanguageID.BENGALI]: defineKey('DMN_BENGALI'),
  [LanguageID.CHINESE]: defineKey('DMN_CHINESE'),
  [LanguageID.ENGLISH]: defineKey('DMN_ENGLISH'),
  [LanguageID.FRENCH]: defineKey('DMN_FRENCH'),
  [LanguageID.GERMAN]: defineKey('DMN_GERMAN'),
  [LanguageID.HINDI]: defineKey('DMN_HINDI'),
  [LanguageID.ITALIAN]: defineKey('DMN_ITALIAN'),
  [LanguageID.JAPANESE]: defineKey('DMN_JAPANESE'),
  [LanguageID.KOREAN]: defineKey('DMN_KOREAN'),
  [LanguageID.POLISH]: defineKey('DMN_POLISH'),
  [LanguageID.SPANISH]: defineKey('DMN_SPANISH'),
  [LanguageID.PORTUGUESE]: defineKey('DMN_PORTUGUESE'),
  [LanguageID.RUSSIAN]: defineKey('DMN_RUSSIAN'),
  [LanguageID.TURKISH]: defineKey('DMN_TURKISH'),
  [LanguageID.VIETNAMESE]: defineKey('DMN_VIETNAMESE'),
  [LanguageID.ROMANIAN]: defineKey('DMN_VIETNAMESE'),
};

export const FurnishingLabelTranslations: Record<Furnishings, string> = {
  [Furnishings.Furnished]: defineKey('DMN_FURNISHED'),
  [Furnishings.Uncarpeted]: defineKey('DMN_UNCARPETED'),
  [Furnishings.Unfurnished]: defineKey('DMN_UNFURNISHED'),
};

export const RoomTypeLabelTranslations: Record<RoomTypeID, string> = {
  [RoomTypeID.Room]: defineKey('DMN_ROOM'),
  [RoomTypeID.Apartment]: defineKey('DMN_APARTMENT'),
  [RoomTypeID.Studio]: defineKey('DMN_STUDIO'),
  [RoomTypeID.Antikraak]: defineKey('DMN_ANTI_SQUAT'),
  [RoomTypeID.StudentResidence]: defineKey('DMN_STUDENT_RESIDENCE'),
};
export type ExcludeListRoommateID =
  | RoommateNumberID.Two
  | RoommateNumberID.Three
  | RoommateNumberID.Five
  | RoommateNumberID.Six
  | RoommateNumberID.Seven
  | RoommateNumberID.One
  | RoommateNumberID.NotRelevant;
export const RoommateNumberofPersonLabelTranslations: Record<
  Exclude<RoommateNumberID, ExcludeListRoommateID>,
  string
> = {
  [RoommateNumberID.None]: defineKey('DMN_NONE'),
  [RoommateNumberID.Four]: defineKey('search_page.filters.4_or_less'),
  [RoommateNumberID.Eight]: defineKey('search_page.filters.8_or_less'),
  [RoommateNumberID.MoreThanEight]: defineKey('DMN_MORE_THAN_EIGHT'),
};

export const RoommateNumberTranslations: Record<
  Exclude<
    RoommateNumberID,
    RoommateNumberID.MoreThanEight | RoommateNumberID.NotRelevant
  >,
  string
> = {
  [RoommateNumberID.None]: '0',
  [RoommateNumberID.One]: '1',
  [RoommateNumberID.Two]: '2',
  [RoommateNumberID.Three]: '3',
  [RoommateNumberID.Four]: '4',
  [RoommateNumberID.Five]: '5',
  [RoommateNumberID.Six]: '6',
  [RoommateNumberID.Seven]: '7',
  [RoommateNumberID.Eight]: '8',
};
export const RoommateNumberLabelTranslations: Record<
  RoommateNumberID.MoreThanEight | RoommateNumberID.NotRelevant,
  string
> = {
  [RoommateNumberID.MoreThanEight]: defineKey('DMN_MORE_THAN_EIGHT'),
  [RoommateNumberID.NotRelevant]: defineKey('DMN_NOT_RELEVANT'),
};

export const RoomAbuseTypeTranslations: Record<RoomAbuseType, string> = {
  [RoomAbuseType.Spam]: defineKey('report_listing.reasons_1_duplicate'),
  [RoomAbuseType.Scam]: defineKey('report_listing.reasons_2_fraud'),
  [RoomAbuseType.Agency]: defineKey('report_listing.reasons_3_incorrect_ad'),
  [RoomAbuseType.InappropriateBehavior]: defineKey(
    'report_listing.reasons_4_inappropriate',
  ),
  [RoomAbuseType.NoShow]: defineKey('report_listing.reasons_5_illegal_costs'),
  [RoomAbuseType.Other]: defineKey('report_listing.reasons_other'),
};
