import { RoomTypeID } from '@kamernet/core/ApiClient/Kamernet';
import { Locale } from '@kamernet/core/Intl/types';

export const TYPEANDCITY_EN_NL_MAPPING = (typeAndCity: string) => {
  const nlPathname = typeAndCity.replace(
    /properties|room|apartment|anti-squat|for-rent|student-housing|netherlands/g,
    match => {
      switch (match) {
        case 'properties':
          return 'huurwoningen';
        case 'room':
          return 'kamer';
        case 'apartment':
          return 'appartement';
        case 'anti-squat':
          return 'anti-kraak';
        case 'for-rent':
          return 'huren';
        case 'student-housing':
          return 'studentenwoning';
        case 'netherlands':
          return 'nederland';
        default:
          return match;
      }
    },
  );
  return nlPathname;
};

export const TYPEANDCITY_NL_EN_MAPPING = (typeAndCity: string) => {
  const enPathname = typeAndCity.replace(
    /huurwoningen|kamer|appartement|anti-kraak|studentenwoning|nederland/g,
    match => {
      switch (match) {
        case 'huurwoningen':
          return 'properties';
        case 'kamer':
          return 'room';
        case 'appartement':
          return 'apartment';
        case 'anti-kraak':
          return 'anti-squat';
        case 'studentenwoning':
          return 'student-housing';
        case 'nederland':
          return 'netherlands';
        default:
          return match;
      }
    },
  );
  return enPathname;
};

export const DYNAMIC_ROUTES_PREVIEWADVERT_EN_NL_MAPPING = (
  dynamicPath: string,
) => {
  const nlPathname = dynamicPath.replace(/preview-roomadvert/g, match => {
    switch (match) {
      case 'preview-roomadvert':
        return 'voorbeeldweergave';
      default:
        return match;
    }
  });
  return nlPathname;
};

export const DYNAMIC_ROUTES_PREVIEWADVERT_NL_EN_MAPPING = (
  dynamicPath: string,
) => {
  const nlPathname = dynamicPath.replace(/voorbeeldweergave/g, match => {
    switch (match) {
      case 'voorbeeldweergave':
        return 'preview-roomadvert';
      default:
        return match;
    }
  });
  return nlPathname;
};

export const DYNAMIC_ROUTES_LCF_EN_NL_MAPPING = (dynamicPath: string) => {
  const nlPathname = dynamicPath.replace(/create-listing/g, match => {
    switch (match) {
      case 'create-listing':
        return 'maken-advertentie';
      default:
        return match;
    }
  });
  return nlPathname;
};

export const DYNAMIC_ROUTES_LCF_NL_EN_MAPPING = (dynamicPath: string) => {
  const nlPathname = dynamicPath.replace(/maken-advertentie/g, match => {
    switch (match) {
      case 'maken-advertentie':
        return 'create-listing';
      default:
        return match;
    }
  });
  return nlPathname;
};

export const DYNAMIC_ROUTES_CONVERSATION_EN_NL_MAPPING = (
  dynamicPath: string,
) => {
  const nlPathname = dynamicPath.replace(/my-messages/g, match => {
    switch (match) {
      case 'my-messages':
        return 'mijn-berichten';
      default:
        return match;
    }
  });
  return nlPathname;
};

export const DYNAMIC_ROUTES_CONVERSATION_NL_EN_MAPPING = (
  dynamicPath: string,
) => {
  const nlPathname = dynamicPath.replace(/mijn-berichten/g, match => {
    switch (match) {
      case 'mijn-berichten':
        return 'my-messages';
      default:
        return match;
    }
  });
  return nlPathname;
};

export const LISTING_DYNAMIC_ROUTES_EN_TO_NL_MAPPING = (pathname: string) => {
  const pattern = /^\/for-rent\/.*$/;
  const previewAdvertpattern = /^\/preview-roomadvert\/.*$/;
  const lcfPattern = /^\/create-listing\/.*$/;
  const conversationPattern = /^\/my-messages\/.*$/;

  if (pattern.test(pathname)) {
    return TYPEANDCITY_EN_NL_MAPPING(pathname);
  }

  if (previewAdvertpattern.test(pathname)) {
    return DYNAMIC_ROUTES_PREVIEWADVERT_EN_NL_MAPPING(pathname);
  }

  if (lcfPattern.test(pathname)) {
    return DYNAMIC_ROUTES_LCF_EN_NL_MAPPING(pathname);
  }

  if (conversationPattern.test(pathname)) {
    return DYNAMIC_ROUTES_CONVERSATION_EN_NL_MAPPING(pathname);
  }

  return pathname;
};

export const LISTING_DYNAMIC_ROUTES_NL_TO_EN_MAPPING = (pathname: string) => {
  const pattern = /^\/for-rent\/.*$/;
  const previewAdvertpattern = /^\/preview-roomadvert\/.*$/;
  const lcfPattern = /^\/create-listing\/.*$/;
  const conversationPattern = /^\/my-messages\/.*$/;

  if (pattern.test(pathname)) {
    return TYPEANDCITY_NL_EN_MAPPING(pathname);
  }

  if (previewAdvertpattern.test(pathname)) {
    return DYNAMIC_ROUTES_PREVIEWADVERT_NL_EN_MAPPING(pathname);
  }

  if (lcfPattern.test(pathname)) {
    return DYNAMIC_ROUTES_LCF_NL_EN_MAPPING(pathname);
  }

  if (conversationPattern.test(pathname)) {
    return DYNAMIC_ROUTES_CONVERSATION_NL_EN_MAPPING(pathname);
  }

  return pathname;
};

export const LISTING_TYPE_SLUGS_EN = [
  'room',
  'apartment',
  'studio',
  'anti-squat',
  'student-housing',
  'properties',
] as const;

export type ListingTypeSlugEN = (typeof LISTING_TYPE_SLUGS_EN)[number];

export const LISTING_TYPE_SLUGS_NL = [
  'kamer',
  'appartement',
  'studio',
  'anti-kraak',
  'studentenwoning',
  'huurwoningen',
] as const;

export type ListingTypeSlugNL = (typeof LISTING_TYPE_SLUGS_NL)[number];

export type ListingTypeSlug =
  | (typeof LISTING_TYPE_SLUGS_EN)[number]
  | (typeof LISTING_TYPE_SLUGS_NL)[number];

export const LISTING_TYPE_SLUG_TO_ID_MAP: Record<
  Exclude<ListingTypeSlug, 'properties' | 'huurwoningen'>,
  RoomTypeID
> = {
  room: RoomTypeID.Room,
  kamer: RoomTypeID.Room,
  apartment: RoomTypeID.Apartment,
  appartement: RoomTypeID.Apartment,
  studio: RoomTypeID.Studio,
  'anti-squat': RoomTypeID.Antikraak,
  'anti-kraak': RoomTypeID.Antikraak,
  'student-housing': RoomTypeID.StudentResidence,
  studentenwoning: RoomTypeID.StudentResidence,
};

export const LISTING_TYPE_ID_TO_SLUG_MAP_EN: Record<
  RoomTypeID,
  ListingTypeSlugEN
> = {
  [RoomTypeID.Room]: 'room',
  [RoomTypeID.Apartment]: 'apartment',
  [RoomTypeID.Studio]: 'studio',
  [RoomTypeID.Antikraak]: 'anti-squat',
  [RoomTypeID.StudentResidence]: 'student-housing',
};

export const LISTING_TYPE_ID_TO_SLUG_MAP_NL: Record<
  RoomTypeID,
  ListingTypeSlugNL
> = {
  [RoomTypeID.Room]: 'kamer',
  [RoomTypeID.Apartment]: 'appartement',
  [RoomTypeID.Studio]: 'studio',
  [RoomTypeID.Antikraak]: 'anti-kraak',
  [RoomTypeID.StudentResidence]: 'studentenwoning',
};

export function getListingTypeSlugById(
  listingTypeId: RoomTypeID | null | undefined,
  locale: Locale,
): ListingTypeSlug {
  if (listingTypeId === null || listingTypeId === undefined) {
    return locale === Locale.en ? 'properties' : 'huurwoningen';
  }

  return locale === Locale.en
    ? LISTING_TYPE_ID_TO_SLUG_MAP_EN[listingTypeId]
    : LISTING_TYPE_ID_TO_SLUG_MAP_NL[listingTypeId];
}
