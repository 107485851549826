import { GetServerSideProps } from 'next';

export function definePageLoader<T extends object>(
  fn: GetServerSideProps<T>,
): GetServerSideProps<T> {
  return ctx => {
    if (ctx.res.writableFinished || ctx.res.writableEnded) {
      return new Promise(resolve => {
        resolve({ props: {} as T });
      });
    }
    return fn(ctx);
  };
}
