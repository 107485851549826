import React from 'react';
import {
  FormControl,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import { CommonStyles } from '@kamernet/styles/CommonStyles';
import { useIntl } from '@kamernet/core/Intl';
import { RentalPriceIDLabels } from '@kamernet/modules/Listing/messages';
import { RentalPriceID } from '@kamernet/core/ApiClient/Kamernet';
import { RentalPriceIDValues } from '@kamernet/modules/Listing/types';

export interface MaxRentInputProps {
  className?: string;
  value: RentalPriceID | null;
  onChange: (value: RentalPriceID) => void;
}

export const MaxRentInput = ({
  className,
  value,
  onChange,
}: MaxRentInputProps) => {
  const { T } = useIntl();

  const onChangeInternal = React.useCallback(
    (event: SelectChangeEvent<RentalPriceID>) => {
      onChange(event.target.value as RentalPriceID);
    },
    [onChange],
  );

  const options = React.useMemo(() => {
    return RentalPriceIDValues.map(price => (
      <MenuItem key={price} value={price}>
        <ListItemText primary={RentalPriceIDLabels[price]} />
      </MenuItem>
    ));
  }, []);

  const valueRenderer = React.useCallback(
    (selected: RentalPriceID) => (
      <React.Fragment>
        <Typography
          className={CommonStyles.margin_right_1}
          component="span"
          variant="body2"
          color="secondary.light"
        >
          {T('LBL_MAX')}
        </Typography>
        <Typography component="span" variant="body2">
          {selected === RentalPriceID.Eur0
            ? T('LBL_SELECT_PLACEHOLDER_RENTAL_PRICE')
            : RentalPriceIDLabels[selected]}
        </Typography>
      </React.Fragment>
    ),
    [T],
  );

  return (
    <FormControl className={className}>
      <Select
        variant="standard"
        disableUnderline
        value={value ?? RentalPriceID.Eur0}
        renderValue={valueRenderer}
        data-testid="max-rent-input"
        onChange={onChangeInternal}
      >
        {options}
      </Select>
    </FormControl>
  );
};
