import React from 'react';

import { useRouter } from 'next/router';

import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import { ButtonProps, Typography } from '@mui/material';
import classnames from 'classnames';

import { track } from '@hbf/analytics';

import { AnalyticsEvent } from '@kamernet/core/Analytics/types';
import {
  RadiusID,
  Location,
  RentalPriceID,
  SurfaceMinimumID,
  RoomTypeID,
} from '@kamernet/core/ApiClient/Kamernet';
import { reportError } from '@kamernet/core/Errors';
import { CustomTags, ErrorModules } from '@kamernet/core/Errors/types';
import { useIntl } from '@kamernet/core/Intl';
import { CommonStyles } from '@kamernet/styles/CommonStyles';
import { useMediaIsDesktop } from '@kamernet/utilities/Media/useMediaIsDesktop';

import { ListingsContext } from '@kamernet/modules/Listing/context/ListingsContext';
import { useFilterStateDispatcher } from '@kamernet/modules/Listing/hooks/useFilterStateDispatcher';

import { useListingsPageUrl } from '../../hooks/useListingsPageUrl';
import { LocationAutocomplete } from '../LocationAutocomplete';

import { ListingTypesInput } from './components/ListingTypesInput';
import { MaxRentInput } from './components/MaxRentInput';
import { MinSizeInput } from './components/MinSizeInput';
import { RadiusInput } from './components/RadiusInput';
import { SubmitButton } from './components/SubmitButton';
import styles from './ListingSearchBar.module.css';

export interface ListingSearchBarProps {
  validationMessageStrategy?: 'onInputChange' | 'always';
  className?: string;
  disableUrlUpdateOnFilterChange?: boolean;
  submitButtonProps?: ButtonProps;
  isLocationRequired?: boolean;
  onLocationInputChange?: () => void;
}

export const ListingSearchBar = ({
  validationMessageStrategy = 'onInputChange',
  className,
  disableUrlUpdateOnFilterChange,
  submitButtonProps,
  isLocationRequired,
  onLocationInputChange,
}: ListingSearchBarProps) => {
  const { T } = useIntl();
  const [showValidationMessage, setShowValidationMessage] = React.useState(
    validationMessageStrategy === 'always',
  );
  const router = useRouter();
  const isDesktop = useMediaIsDesktop();
  const { listingsState } = ListingsContext.useContext();
  const { generateUrl } = useListingsPageUrl();

  const { filterStateDispatcher } = useFilterStateDispatcher({
    disableUrlUpdate: disableUrlUpdateOnFilterChange,
  });

  const [location, setLocation] = React.useState<Location | null>(
    listingsState.filterResult.filters.location || null,
  );

  const [radiusId, setRadiusId] = React.useState(
    listingsState.filterResult.filters.radiusId ?? RadiusID.Km10,
  );
  const { listingTypeIds } = listingsState.filterResult.filters;
  const [maxRentalPriceId, setMaxRentalPriceId] = React.useState(
    listingsState.filterResult.filters.maxRentalPriceId,
  );

  const [surfaceMinimumId, setSurfaceMinimumId] = React.useState(
    listingsState.filterResult.filters.surfaceMinimumId,
  );

  const [isRadiusInputVisible, setIsRadiusInputVisible] =
    React.useState<boolean>(true);

  const onRadiusChange = React.useCallback(
    (selectedRadius: RadiusID) => {
      filterStateDispatcher({
        ...listingsState.filterResult.filters,
        radiusId: selectedRadius,
        pageNo: 1,
      });
      setRadiusId(selectedRadius);
    },
    [filterStateDispatcher, listingsState.filterResult.filters],
  );
  const onLocationChange = React.useCallback(
    (selectedLocation: Location | null) => {
      if (validationMessageStrategy === 'onInputChange') {
        setShowValidationMessage(false);
      }

      filterStateDispatcher({
        ...listingsState.filterResult.filters,
        location: selectedLocation,
        streetName: null,
        streetSlug: null,
        pageNo: 1,
      });
      setLocation(selectedLocation);

      if (onLocationInputChange) {
        onLocationInputChange();
      }
    },
    [
      filterStateDispatcher,
      listingsState.filterResult.filters,
      onLocationInputChange,
      validationMessageStrategy,
    ],
  );
  const onListingTypeChange = React.useCallback(
    (selectedListingTypes: RoomTypeID[]) => {
      filterStateDispatcher({
        ...listingsState.filterResult.filters,
        listingTypeIds: selectedListingTypes,
        pageNo: 1,
      });
    },
    [filterStateDispatcher, listingsState.filterResult.filters],
  );
  const onRentChange = React.useCallback(
    (selectedRent: RentalPriceID | null) => {
      filterStateDispatcher({
        ...listingsState.filterResult.filters,
        maxRentalPriceId: selectedRent,
        pageNo: 1,
      });
      setMaxRentalPriceId(selectedRent);
    },
    [filterStateDispatcher, listingsState.filterResult.filters],
  );
  const onRoomSizeChange = React.useCallback(
    (selectedRoomSize: SurfaceMinimumID | null) => {
      filterStateDispatcher({
        ...listingsState.filterResult.filters,
        surfaceMinimumId: selectedRoomSize,
        pageNo: 1,
      });
      setSurfaceMinimumId(selectedRoomSize);
    },
    [filterStateDispatcher, listingsState.filterResult.filters],
  );

  const onSubmit = React.useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      if (!location && isLocationRequired) {
        setShowValidationMessage(true);
        return;
      }

      track(AnalyticsEvent.ClickedSearchButton);

      try {
        await router.push(generateUrl(listingsState.filterResult.filters));
      } catch (error) {
        reportError(error as Error, {
          extra: {
            context: 'Search page - generateURL failed',
            metaData: { error },
          },
          tags: {
            [CustomTags.KN_Module]: ErrorModules.Listing,
          },
        });
      }
    },
    [
      location,
      isLocationRequired,
      router,
      generateUrl,
      listingsState.filterResult.filters,
    ],
  );

  const onLocationPopperToggle = React.useCallback((isPopperOpen: boolean) => {
    setIsRadiusInputVisible(!isPopperOpen);
  }, []);

  return (
    <form
      autoComplete="off"
      className={classnames(styles.root, className)}
      role="search"
      onSubmit={onSubmit}
    >
      <div className={styles.inputContainer}>
        <div className={styles.locationAndRadiusContainer}>
          <LocationOnOutlinedIcon />
          <LocationAutocomplete
            value={location}
            borderless
            onPopperToggle={onLocationPopperToggle}
            onChange={onLocationChange}
          />

          {isDesktop ? (
            isRadiusInputVisible && (
              <RadiusInput value={radiusId} onChange={onRadiusChange} />
            )
          ) : (
            <SubmitButton {...submitButtonProps} />
          )}
        </div>
        {isLocationRequired && !location && showValidationMessage && (
          <div className={CommonStyles.padding_left_1}>
            <Typography variant="caption" color="error">
              {T('listing_module.listing_search_bar.location_is_required')}
            </Typography>
          </div>
        )}
      </div>
      {isDesktop && (
        <React.Fragment>
          <div className={styles.inputContainer}>
            <ListingTypesInput
              className={styles.listingTypesInput}
              value={
                listingTypeIds && listingTypeIds?.length > 0
                  ? listingTypeIds
                  : []
              }
              onChange={onListingTypeChange}
            />
          </div>
          <div className={styles.inputContainer}>
            <MaxRentInput
              className={styles.maxRentInput}
              value={maxRentalPriceId || null}
              onChange={onRentChange}
            />
          </div>
          <div className={styles.inputContainer}>
            <MinSizeInput
              className={styles.minSizeInput}
              value={surfaceMinimumId || null}
              onChange={onRoomSizeChange}
            />
          </div>
          <SubmitButton {...submitButtonProps} />
        </React.Fragment>
      )}
    </form>
  );
};
