import {
  ListingRequest,
  FindListingsResponse,
} from '@kamernet/core/ApiClient/Kamernet';

export type FiltersList = ListingRequest;
export type Listings = FindListingsResponse | Partial<FindListingsResponse>;

export enum FilterActionTypes {
  UPDATE_FILTER = 'UPDATE_FILTER',
  UPDATE_FILTER_COUNT = 'UPDATE_FILTER_COUNT',
  CLEAR_FILTERS = 'CLEAR_FILTERS',
}
export enum ListingActionTypes {
  UPDATE_LISTING = 'UPDATE_LISTING',
  UPDATE_LISTING_LOADING_STATE = 'UPDATE_LISTING_LOADING_STATE',
}

export type FilterActions =
  | {
      type: FilterActionTypes.UPDATE_FILTER;
      payload: FiltersList;
    }
  | {
      type: FilterActionTypes.UPDATE_FILTER_COUNT;
      payload: number;
    }
  | {
      type: FilterActionTypes.CLEAR_FILTERS;
      payload: FiltersList;
    };

export type ListingActions =
  | {
      type: ListingActionTypes.UPDATE_LISTING;
      payload: Listings;
    }
  | {
      type: ListingActionTypes.UPDATE_LISTING_LOADING_STATE;
      payload: boolean;
    };

export type Actions = FilterActions | ListingActions;
