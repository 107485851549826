import { Enum } from '@ha/core/utilities';

import {
  ListingRequest,
  RadiusID,
  RentalPriceID,
  RoomAdvertSortOptionID,
  RoomTypeID,
  Statuses,
  SurfaceMinimumID,
} from '@kamernet/core/ApiClient/Kamernet';

export const RadiusIDValues: RadiusID[] = Enum.values(RadiusID).filter(
  value =>
    ![RadiusID.Km15, RadiusID.Km30, RadiusID.Km50].some(item => item === value), // Exclude values that Business doesn't want to display
);

export const SurfaceMinimumIDValues: SurfaceMinimumID[] =
  Enum.values(SurfaceMinimumID);

export const RentalPriceIDValues: RentalPriceID[] = Enum.values(
  RentalPriceID,
).filter(value => ![RentalPriceID.Eur20000].some(item => item === value)); // Exclude values that Business doesn't want to display

export const RoomTypeIDValues: RoomTypeID[] = Enum.values(RoomTypeID);

export type Filters = Partial<ListingRequest>;

export enum SearchView {
  List = 1,
  Map = 2,
  MapTiles = 3,
}

export const RoomAdvertSortOptionIDValues: RoomAdvertSortOptionID[] =
  Enum.values(RoomAdvertSortOptionID).filter(
    value => value !== RoomAdvertSortOptionID.SocialConnectionsFirst,
  );

export enum ListingChipLabels {
  New = 0,
  TopAd = 1,
  ReactForFree = 2,
  StillAvailable = 3,
}

export enum ConstantsLDP {
  MoveUpDuration = 5,
  FreeMoveUpDuration = 15,
}

export const STUDENT_HOUSING_SUITABLE_FOR_STATUS_IDS = [
  Statuses.Student,
  Statuses.WorkingStudent,
];
