import { Filters } from '@kamernet/modules/Listing/types';

const excludePageLevelFilters = (filters: Filters) => {
  const excludeList = [
    'pageNo',
    'searchview',
    'rowsPerPage',
    'variant',
    'listingSortOptionId',
    'surfaceMinimumId',
    'radiusId',
    'maxRentalPriceId',
    'streetName',
    'streetSlug',
  ];
  return Object.fromEntries(
    Object.entries(filters).filter(filterItem => {
      const [key] = filterItem;
      return !excludeList.includes(key);
    }),
  );
};
export const getFilterCount = (
  filters: Filters,
  excludedProperties: Set<string> = new Set(),
) => {
  let count = 0;
  const isNonEmptyArray = (value: unknown) =>
    Array.isArray(value) && value.length > 0;
  const isActiveBoolean = (value: unknown) =>
    typeof value === 'boolean' && value;
  const isNumber = (value: unknown) => typeof value === 'number';
  const isNonEmptyAndDefaultString = (value: unknown) =>
    Object.prototype.toString.call(value) === '[object String]' &&
    value &&
    value !== '-';
  Object.entries(excludePageLevelFilters(filters)).forEach(([key, filter]) => {
    if (!excludedProperties.has(key) && filter != null) {
      if (
        isNonEmptyArray(filter) ||
        isActiveBoolean(filter) ||
        isNumber(filter) ||
        isNonEmptyAndDefaultString(filter)
      ) {
        count += 1;
      }
    }
    return false;
  });
  return count;
};

export const sanitizeArrayField = (value: any) => {
  if (value && Array.isArray(value) && value.length < 1) {
    return undefined;
  }
  return value;
};

export const sanitizeBooleanField = (value: any) => {
  if (typeof value === 'boolean' && !value) {
    return undefined;
  }
  return value;
};

export const sanitizeAgeField = (value: any) => {
  if (typeof value === 'string' && value && value === '-') {
    return undefined;
  }
  return value;
};
