import {
  ListingRequest,
  RentalPriceID,
  RoomTypeID,
} from '@kamernet/core/ApiClient/Kamernet';

import { STUDENT_HOUSING_SUITABLE_FOR_STATUS_IDS } from '../types';

const studentHouseMaxPriceByCityName: Record<string, RentalPriceID> = {
  Groningen: RentalPriceID.Eur500,
  Rotterdam: RentalPriceID.Eur600,
};

/**
 * This is a workaround to show any listing type which is suitable for student/working-student.
 * Without this workaround, only rooms marked as student housing will be displayed which usually
 * has very low supply.
 */

export function adjustListingRequestForApiCall(
  listingRequest: ListingRequest,
): ListingRequest {
  if (!listingRequest) {
    return listingRequest;
  }

  const newListingRequest = JSON.parse(
    JSON.stringify(listingRequest),
  ) as ListingRequest;

  newListingRequest.listingTypeIds = newListingRequest.listingTypeIds || [];

  newListingRequest.suitableForStatusIds =
    newListingRequest.suitableForStatusIds || [];

  const hasStudentHousingListingType = newListingRequest.listingTypeIds.some(
    item => item === RoomTypeID.StudentResidence,
  );

  if (
    hasStudentHousingListingType &&
    newListingRequest.listingTypeIds.length === 1
  ) {
    if (!listingRequest.maxRentalPriceId && listingRequest.location?.cityName) {
      const studentHouseMaxPrice =
        studentHouseMaxPriceByCityName[listingRequest.location.cityName];

      if (studentHouseMaxPrice) {
        newListingRequest.maxRentalPriceId = studentHouseMaxPrice;
      }
    }

    newListingRequest.suitableForStatusIds = [
      ...STUDENT_HOUSING_SUITABLE_FOR_STATUS_IDS,
    ];
  } else if (
    hasStudentHousingListingType &&
    newListingRequest.suitableForStatusIds.length !== 0
  ) {
    for (const statusId of STUDENT_HOUSING_SUITABLE_FOR_STATUS_IDS) {
      if (
        !newListingRequest.suitableForStatusIds.some(item => item === statusId)
      ) {
        newListingRequest.suitableForStatusIds = [
          ...newListingRequest.suitableForStatusIds,
          statusId,
        ];
      }
    }
  }

  if (hasStudentHousingListingType) {
    newListingRequest.listingTypeIds = newListingRequest.listingTypeIds.filter(
      item => item !== RoomTypeID.StudentResidence,
    );
  }

  return newListingRequest;
}
