import * as React from 'react';

import { track } from '@hbf/analytics';

import { AnalyticsEvent } from '@kamernet/core/Analytics/types';
import { getSearchFilterAppliedEventPayload } from '@kamernet/core/Analytics/utilities/getSearchFilterAppliedEventPayload';
import { ListingRequest } from '@kamernet/core/ApiClient/Kamernet';

import { ListingsContext } from '@kamernet/modules/Listing/context/ListingsContext';
import { FilterActionTypes } from '@kamernet/modules/Listing/context/types';

import { getFilterCount } from '../pages/Listings/components/FilterUtils';
import { SearchView } from '../types';

import { useListingsPageUrl } from './useListingsPageUrl';
import { useListingStateDispatcher } from './useListingStateDispatcher';

export const useFilterStateDispatcher = ({
  disableUrlUpdate,
}: {
  disableUrlUpdate?: boolean;
} = {}) => {
  const { dispatch } = ListingsContext.useContext();
  const { listingStateDispatcher } = useListingStateDispatcher();
  const { updateUrl } = useListingsPageUrl();
  const filterStateDispatcher = React.useCallback(
    (listingRequest: ListingRequest) => {
      dispatch({
        type: FilterActionTypes.UPDATE_FILTER,
        payload: listingRequest,
      });

      dispatch({
        type: FilterActionTypes.UPDATE_FILTER_COUNT,
        payload: getFilterCount(listingRequest),
      });

      if (!disableUrlUpdate) {
        updateUrl(listingRequest);
      }

      track(AnalyticsEvent.SearchFilterApplied, {
        filtertype: getSearchFilterAppliedEventPayload(listingRequest),
      });

      if (listingRequest.searchview === SearchView.List) {
        listingStateDispatcher(listingRequest);
      }
    },
    [disableUrlUpdate, dispatch, listingStateDispatcher, updateUrl],
  );

  return { filterStateDispatcher };
};
