import React from 'react';

import {
  FindListingsResponse,
  ListingRequest,
} from '@kamernet/core/ApiClient/Kamernet';

import { getFilterCount } from '../pages/Listings/components/FilterUtils';

import { IListingsState, ListingsContext } from './ListingsContext';
import { mainReducer } from './Reducer';
import { Actions } from './types';

export const ListingsProvider = ({
  children,
  initialListings,
  initialFilters,
}: {
  children: React.ReactNode;
  initialListings: FindListingsResponse | null;
  initialFilters: ListingRequest | null;
}) => {
  // Save the search criteria from serverside props
  const initialState: IListingsState = {
    filterResult: {
      count: initialFilters ? getFilterCount(initialFilters) : 0,
      filters: initialFilters || ({} as ListingRequest),
    },
    listingsResult: {
      isLoading: false,
      listings: initialListings || {},
    },
  };
  const [listingsState, dispatch] = React.useReducer<
    React.Reducer<IListingsState, Actions>
  >(mainReducer, initialState);

  const value = React.useMemo(
    () => ({ listingsState, dispatch }),
    [listingsState],
  );

  return (
    <ListingsContext.Provider value={value}>
      {children}
    </ListingsContext.Provider>
  );
};
