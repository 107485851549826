import React from 'react';
import {
  FormControl,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import { useIntl } from '@kamernet/core/Intl';
import { CommonStyles } from '@kamernet/styles/CommonStyles';
import { SurfaceMinimumIDLabels } from '@kamernet/modules/Listing/messages';
import { SurfaceMinimumID } from '@kamernet/core/ApiClient/Kamernet';
import { SurfaceMinimumIDValues } from '@kamernet/modules/Listing/types';

export const EMPTY_OPTION_VALUE = 'EMPTY';
export const EMPTY_OPTION_LABEL = '- m²';

export interface MinSizeInputProps {
  className?: string;
  value: SurfaceMinimumID | null;
  onChange: (value: SurfaceMinimumID | null) => void;
}

export const MinSizeInput = ({
  className,
  value,
  onChange,
}: MinSizeInputProps) => {
  const { T } = useIntl();

  const onChangeInternal = React.useCallback(
    (event: SelectChangeEvent<SurfaceMinimumID | string>) => {
      if (event.target.value === EMPTY_OPTION_VALUE) {
        onChange(null);
      } else {
        onChange(event.target.value as SurfaceMinimumID);
      }
    },
    [onChange],
  );

  const options = React.useMemo(() => {
    return SurfaceMinimumIDValues.map(surface => (
      <MenuItem key={surface} value={surface}>
        <ListItemText primary={SurfaceMinimumIDLabels[surface]} />
      </MenuItem>
    ));
  }, []);

  const valueRenderer = React.useCallback(
    (selected: SurfaceMinimumID | typeof EMPTY_OPTION_VALUE) => (
      <React.Fragment>
        <Typography
          className={CommonStyles.margin_right_1}
          component="span"
          color="secondary.light"
          variant="body2"
        >
          {T('LBL_MIN')}
        </Typography>
        <Typography component="span" variant="body2">
          {selected === EMPTY_OPTION_VALUE
            ? T('LBL_SELECT_PLACEHOLDER_SURFACE')
            : SurfaceMinimumIDLabels[selected]}
        </Typography>
      </React.Fragment>
    ),
    [T],
  );

  return (
    <FormControl className={className}>
      <Select
        variant="standard"
        disableUnderline
        value={value ?? EMPTY_OPTION_VALUE}
        renderValue={valueRenderer}
        data-testid="min-size-input"
        onChange={onChangeInternal}
      >
        <MenuItem value={EMPTY_OPTION_VALUE}>
          <ListItemText primary={EMPTY_OPTION_LABEL} />
        </MenuItem>
        {options}
      </Select>
    </FormControl>
  );
};
