import { createRequiredContext } from '@kamernet/utilities/RequiredContext';

import { FilterActions, FiltersList, ListingActions, Listings } from './types';

export type Filter = {
  count: number;
  filters: FiltersList;
};

export type ListingsResult = {
  isLoading: boolean;
  listings: Listings;
};

export type MapListings = {
  isLoading: boolean;
  listings: Listings;
};

export type IListingsState = {
  filterResult: Filter;
  listingsResult: ListingsResult;
};

export interface IListings {
  listingsState: IListingsState;
  dispatch: React.Dispatch<FilterActions | ListingActions>;
}

export const ListingsContext =
  createRequiredContext<IListings>('ListingsContext');
