import React from 'react';

import {
  Autocomplete,
  AutocompleteRenderInputParams,
  CircularProgress,
  FormControl,
  TextField,
  Typography,
  capitalize,
} from '@mui/material';
import classnames from 'classnames';

import debounce from 'debounce';

import { Location } from '@kamernet/core/ApiClient/Kamernet';
import { AppServicesContext } from '@kamernet/core/AppServices';
import { useIntl } from '@kamernet/core/Intl';

import styles from './LocationAutocomplete.module.css';

const areLocationsEqual = (first: Location, second: Location) =>
  first.name === second.name;

const getOptionLabel = (option: Location) => option.name;

const filterOptions = (options: Location[]) => options; // we return the options as it is because filtering is done by server, not in client side.

export interface LocationAutocompleteProps {
  value: Location | null;
  borderless?: boolean;
  onChange: (value: Location | null) => void;
  onPopperToggle?: (isPopperOpen: boolean) => void;
}

export const LocationAutocomplete = ({
  value,
  borderless,
  onChange,
  onPopperToggle,
}: LocationAutocompleteProps) => {
  const { T } = useIntl();
  const abortControllerRef = React.useRef<AbortController | null>(null);
  const queryRef = React.useRef<string>(value?.name || '');
  const { apiClient } = AppServicesContext.useContext();
  const [loadingOptions, setLoadingOptions] = React.useState<boolean>(false);

  const [options, setOptions] = React.useState<Location[]>(
    value == null ? [] : [value],
  );

  const [popperPlaceholderText, setPopperPlaceholderText] =
    React.useState<string>(T('LBL_SELECT_PLACEHOLDER_CITY_EMPTY'));

  const textFieldRenderer = React.useCallback(
    (params: AutocompleteRenderInputParams) => (
      <TextField
        {...params}
        label={
          <Typography
            variant="body2"
            color="secondary.light"
            className={styles.placeHolder}
          >
            {capitalize(T('LBL_CITY_OR_POSTAL_CODE'))}
          </Typography>
        }
        InputLabelProps={{
          'data-testid': 'location-autocomplete/label',
        }}
        size="small"
        InputProps={{
          ...params.InputProps,
          inputProps: {
            ...params.inputProps,
            autoComplete: 'nope',
          },
          endAdornment: (
            <React.Fragment>
              {loadingOptions && (
                <CircularProgress color="secondary" size={20} />
              )}
              {params.InputProps.endAdornment}
            </React.Fragment>
          ),
        }}
      />
    ),
    [T, loadingOptions],
  );

  const fetchOptionsDebounced = React.useMemo(() => {
    return debounce(() => {
      setLoadingOptions(true);
      const abortController = new AbortController();
      abortControllerRef.current = abortController;
      apiClient.other
        .locationSuggestions(queryRef.current, {
          signal: abortController.signal,
        })
        .then(response => {
          setLoadingOptions(false);
          const locationOptions = response.data || [];
          setOptions(locationOptions);
          if (locationOptions.length === 0) {
            setPopperPlaceholderText(T('LBL_SELECT_CITY_NO_MATCH'));
          }
        })
        .catch(() => {
          // continue regardless of error
        });
    }, 500);
  }, [T, apiClient]);

  const onQueryChange = React.useCallback(
    (_event: React.SyntheticEvent, query: string, reason: string) => {
      queryRef.current = query;
      abortControllerRef.current?.abort();
      if (query.length === 0) {
        setOptions([]);
        setPopperPlaceholderText(T('LBL_SELECT_PLACEHOLDER_CITY_EMPTY'));
      }

      if (reason !== 'input') {
        return;
      }

      if (query.length >= 1) {
        fetchOptionsDebounced();
      }
    },
    [T, fetchOptionsDebounced],
  );

  const onBlur = React.useCallback(() => {
    abortControllerRef.current?.abort();
    setLoadingOptions(false);
    if (
      value != null &&
      queryRef.current.toLowerCase() !== value.name.toLocaleLowerCase()
    ) {
      onChange(null);
    }
  }, [onChange, value]);

  const onLocationChangeInternal = React.useCallback(
    (_event: React.SyntheticEvent, location: Location | null) => {
      // When a value selected, we abort any existing API requests using the abort controller
      abortControllerRef.current?.abort();
      setLoadingOptions(false);
      onChange(location);
    },
    [onChange],
  );

  const onPopperOpen = React.useCallback(() => {
    onPopperToggle?.(true);
  }, [onPopperToggle]);

  const onPopperClose = React.useCallback(() => {
    onPopperToggle?.(false);
  }, [onPopperToggle]);

  return (
    <FormControl className={styles.root}>
      <Autocomplete
        className={classnames(
          styles.noFloatingLabel,
          borderless ? styles.borderless : '',
        )}
        role="searchbox"
        value={value}
        loading={loadingOptions}
        options={options}
        renderInput={textFieldRenderer}
        getOptionLabel={getOptionLabel}
        isOptionEqualToValue={areLocationsEqual}
        noOptionsText={
          <Typography variant="body3" color="secondary.light" role="tooltip">
            {popperPlaceholderText}
          </Typography>
        }
        clearOnBlur={false}
        filterOptions={filterOptions}
        data-testid="location-autocomplete"
        onChange={onLocationChangeInternal}
        onInputChange={onQueryChange}
        onOpen={onPopperOpen}
        onClose={onPopperClose}
        onBlur={onBlur}
      />
    </FormControl>
  );
};
