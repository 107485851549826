import {
  AvailabilityPeriodEnum,
  Furnishings,
  ListingRequest,
  OwnerTypeID,
  RoomTypeID,
  Statuses,
} from '@kamernet/core/ApiClient/Kamernet';

export function getSearchFilterAppliedEventPayload(
  formState: Partial<ListingRequest>,
) {
  const activeFilters = {
    priceMax: Boolean(formState.maxRentalPriceId),
    sizeMin: Boolean(formState.surfaceMinimumId),
    radius: Boolean(formState.radiusId),
    room: formState.listingTypeIds?.includes(RoomTypeID.Room),
    studio: formState.listingTypeIds?.includes(RoomTypeID.Studio),
    apartment: formState.listingTypeIds?.includes(RoomTypeID.Apartment),
    'anti-squat': formState.listingTypeIds?.includes(RoomTypeID.Antikraak),
    uncarpeted: formState.furnishings?.includes(Furnishings.Uncarpeted),
    unfurnished: formState.furnishings?.includes(Furnishings.Unfurnished),
    furnished: formState.furnishings?.includes(Furnishings.Furnished),
    'private bathroom': formState.isBathroomPrivate,
    'private toilet': formState.isTolietPrivate,
    'private kitchen': formState.isKitchenPrivate,
    'internet available': formState.hasInternet,
    'indefinite period': formState.availabilityPeriods?.includes(
      AvailabilityPeriodEnum.Indefinite,
    ),
    'temporary period': formState.availabilityPeriods?.includes(
      AvailabilityPeriodEnum.Temporary,
    ),
    'nr of searching tenants': Boolean(formState.suitableForNumberOfPersonsId),
    gender:
      formState.suitableForGenderIds &&
      formState.suitableForGenderIds?.length > 0,
    age: Boolean(formState.candidateAge),
    'status student': formState.suitableForStatusIds?.includes(
      Statuses.Student,
    ),
    'status working student': formState.suitableForStatusIds?.includes(
      Statuses.WorkingStudent,
    ),
    'status working': formState.suitableForStatusIds?.includes(
      Statuses.Working,
    ),
    'status looking for a job': formState.suitableForStatusIds?.includes(
      Statuses.LookingForJob,
    ),
    'smoking inside allowed': formState.isSmokingInsideAllowed,
    'pets inside allowed': formState.isPetsInsideAllowed,
    'nr of roommates': Boolean(formState.roommateMaxNumberId),
    'gender of roommates':
      formState.roommateGenderIds && formState.roommateGenderIds?.length > 0,
    'owner type roommate': formState.ownerTypeIds?.includes(
      OwnerTypeID.Roommate,
    ),
    'owner type private owner': formState.ownerTypeIds?.includes(
      OwnerTypeID.PrivateOwner,
    ),
    'owner type real estate agent': formState.ownerTypeIds?.includes(
      OwnerTypeID.RealEstateAgent,
    ),
    'owner type other': formState.ownerTypeIds?.includes(OwnerTypeID.Other),
  };

  const filtersList: string[] = Object.keys(activeFilters).filter(key => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (activeFilters as any)[key];
  });

  return filtersList;
}
