import React from 'react';

import {
  Checkbox,
  Chip,
  FormControl,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';

import { RoomTypeID } from '@kamernet/core/ApiClient/Kamernet';
import { useIntl } from '@kamernet/core/Intl';

import { RoomTypeIDTranslations } from '@kamernet/modules/Listing/translations';
import { RoomTypeIDValues } from '@kamernet/modules/Listing/types';

import styles from './ListingTypesInput.module.css';

export interface ListingTypesInputProps {
  className?: string;
  value: RoomTypeID[] | null;
  onChange: (value: RoomTypeID[]) => void;
}

export const ListingTypesInput = ({
  className,
  value,
  onChange,
}: ListingTypesInputProps) => {
  const { T } = useIntl();

  const onChangeInternal = React.useCallback(
    (event: SelectChangeEvent<RoomTypeID[]>) => {
      onChange(event.target.value as RoomTypeID[]);
    },
    [onChange],
  );

  const renderValue = React.useCallback(
    (selectedListingTypes: RoomTypeID[]) => (
      <React.Fragment>
        {selectedListingTypes.length === 0 && (
          <Typography
            variant="body2"
            color="secondary.light"
            className={styles.placeHolder}
          >
            {T('LBL_SELECT_PLACEHOLDER_PROPERTY_TYPE')}
          </Typography>
        )}
        <div className={styles.chipsContainer}>
          {selectedListingTypes.map(selectedListingType => (
            <Chip
              key={selectedListingType}
              data-testid="listing-types-input/chip"
              label={T(RoomTypeIDTranslations[selectedListingType])}
            />
          ))}
        </div>
      </React.Fragment>
    ),
    [T],
  );

  const options = React.useMemo(() => {
    return RoomTypeIDValues.map(listingType => (
      <MenuItem key={listingType} value={listingType}>
        <Checkbox checked={(value ?? []).includes(listingType)} />
        <ListItemText
          primary={
            <Typography variant="body2">
              {T(RoomTypeIDTranslations[listingType])}
            </Typography>
          }
        />
      </MenuItem>
    ));
  }, [T, value]);

  return (
    <FormControl className={className}>
      <Select<RoomTypeID[]>
        multiple
        displayEmpty
        value={value ?? []}
        variant="standard"
        disableUnderline
        renderValue={renderValue}
        data-testid="listing-types-input"
        onChange={onChangeInternal}
      >
        {options}
      </Select>
    </FormControl>
  );
};
