import React from 'react';

import { KamernetError } from '@kamernet/core/ApiClient';
import {
  ListingRequest,
  FindListingsResponse,
  OpResponseCode,
} from '@kamernet/core/ApiClient/Kamernet';
import { AppServicesContext } from '@kamernet/core/AppServices';
import { reportError } from '@kamernet/core/Errors';
import { CustomTags, ErrorModules } from '@kamernet/core/Errors/types';

import { ListingsContext } from '@kamernet/modules/Listing/context/ListingsContext';
import { ListingActionTypes } from '@kamernet/modules/Listing/context/types';

import { adjustListingRequestForApiCall } from '../utilities/adjustListingRequestForApiCall';

import { useStoreAnonymousPSA } from './useStoreAnonymousPSA';

export const useListingStateDispatcher = () => {
  const { dispatch } = ListingsContext.useContext();
  const { apiClient } = AppServicesContext.useContext();
  const { storeAnonymousPSA } = useStoreAnonymousPSA();

  const listingStateDispatcher = React.useCallback(
    (values: ListingRequest) => {
      dispatch({
        type: ListingActionTypes.UPDATE_LISTING_LOADING_STATE,
        payload: true,
      });

      apiClient.listing
        .findListings({
          listingRequest: adjustListingRequestForApiCall(values),
        })
        .then((response: FindListingsResponse) => {
          if (
            response &&
            response.OpResponse.Code === OpResponseCode.OPERATION_SUCCESSFUL
          ) {
            dispatch({
              type: ListingActionTypes.UPDATE_LISTING,
              payload: response,
            });
            dispatch({
              type: ListingActionTypes.UPDATE_LISTING_LOADING_STATE,
              payload: false,
            });
            storeAnonymousPSA(values);
          }
        })
        .catch((error: unknown | KamernetError) => {
          reportError(error as Error, {
            extra: {
              context: 'Listing context state update failed',
              metaData: { error },
            },
            tags: {
              [CustomTags.KN_Module]: ErrorModules.Listing,
            },
          });
          dispatch({
            type: ListingActionTypes.UPDATE_LISTING_LOADING_STATE,
            payload: false,
          });
        });
    },
    [apiClient.listing, dispatch, storeAnonymousPSA],
  );

  return { listingStateDispatcher };
};
