import {
  SurfaceMinimumID,
  RadiusID,
  RentalPriceID,
} from '@kamernet/core/ApiClient/Kamernet';

export const RadiusIDLabels: Record<RadiusID, string> = {
  [RadiusID.Km0]: '+0 km',
  [RadiusID.Km1]: '+1 km',
  [RadiusID.Km2]: '+2 km',
  [RadiusID.Km5]: '+5 km',
  [RadiusID.Km10]: '+10 km',
  [RadiusID.Km15]: '+15 km',
  [RadiusID.Km20]: '+20 km',
  [RadiusID.Km30]: '+30 km',
  [RadiusID.Km50]: '+50 km',
};

export const RentalPriceIDLabels: Record<RentalPriceID, string> = {
  [RentalPriceID.Eur0]: '€ -',
  [RentalPriceID.Eur100]: '€ 100',
  [RentalPriceID.Eur200]: '€ 200',
  [RentalPriceID.Eur300]: '€ 300',
  [RentalPriceID.Eur400]: '€ 400',
  [RentalPriceID.Eur500]: '€ 500',
  [RentalPriceID.Eur600]: '€ 600',
  [RentalPriceID.Eur700]: '€ 700',
  [RentalPriceID.Eur800]: '€ 800',
  [RentalPriceID.Eur900]: '€ 900',
  [RentalPriceID.Eur1000]: '€ 1000',
  [RentalPriceID.Eur1100]: '€ 1100',
  [RentalPriceID.Eur1200]: '€ 1200',
  [RentalPriceID.Eur1300]: '€ 1300',
  [RentalPriceID.Eur1400]: '€ 1400',
  [RentalPriceID.Eur1500]: '€ 1500',
  [RentalPriceID.Eur1750]: '€ 1750',
  [RentalPriceID.Eur2000]: '€ 2000',
  [RentalPriceID.Eur2250]: '€ 2250',
  [RentalPriceID.Eur2500]: '€ 2500',
  [RentalPriceID.Eur2750]: '€ 2750',
  [RentalPriceID.Eur3000]: '€ 3000',
  [RentalPriceID.Eur3250]: '€ 3250',
  [RentalPriceID.Eur3500]: '€ 3500',
  [RentalPriceID.Eur3750]: '€ 3750',
  [RentalPriceID.Eur4000]: '€ 4000',
  [RentalPriceID.Eur4250]: '€ 4250',
  [RentalPriceID.Eur4500]: '€ 4500',
  [RentalPriceID.Eur4750]: '€ 4750',
  [RentalPriceID.Eur5000]: '€ 5000',
  [RentalPriceID.Eur5250]: '€ 5250',
  [RentalPriceID.Eur5500]: '€ 5500',
  [RentalPriceID.Eur5750]: '€ 5750',
  [RentalPriceID.Eur6000]: '€ 6000',
  [RentalPriceID.Eur20000]: '€ 20000',
};

export const SurfaceMinimumIDLabels: Record<SurfaceMinimumID, string> = {
  [SurfaceMinimumID.M6]: '6 m²',
  [SurfaceMinimumID.M8]: '8 m²',
  [SurfaceMinimumID.M10]: '10 m²',
  [SurfaceMinimumID.M12]: '12 m²',
  [SurfaceMinimumID.M14]: '14 m²',
  [SurfaceMinimumID.M16]: '16 m²',
  [SurfaceMinimumID.M18]: '18 m²',
  [SurfaceMinimumID.M20]: '20 m²',
  [SurfaceMinimumID.M22]: '22 m²',
  [SurfaceMinimumID.M24]: '24 m²',
  [SurfaceMinimumID.M26]: '26 m²',
  [SurfaceMinimumID.M28]: '28 m²',
  [SurfaceMinimumID.M30]: '30 m²',
  [SurfaceMinimumID.M32]: '32 m²',
  [SurfaceMinimumID.M34]: '34 m²',
  [SurfaceMinimumID.M36]: '36 m²',
  [SurfaceMinimumID.M38]: '38 m²',
  [SurfaceMinimumID.M40]: '40 m²',
  [SurfaceMinimumID.M45]: '45 m²',
  [SurfaceMinimumID.M50]: '50 m²',
  [SurfaceMinimumID.M60]: '60 m²',
  [SurfaceMinimumID.M70]: '70 m²',
  [SurfaceMinimumID.M80]: '80 m²',
  [SurfaceMinimumID.M90]: '90 m²',
  [SurfaceMinimumID.M100]: '100 m²',
};
