import React from 'react';

import { ListingRequest } from '@kamernet/core/ApiClient/Kamernet';
import { useIntl } from '@kamernet/core/Intl';
import { Locale } from '@kamernet/core/Intl/types';
import { ListingSearchCityRouteParams, ROUTES } from '@kamernet/core/Routing';
import { getListingTypeSlugById } from '@kamernet/core/Routing/dynamicRouterMatcher';

export const useListingsPageUrl = () => {
  const { locale, T } = useIntl();

  const generateUrl = React.useCallback(
    (listingRequest: ListingRequest): string => {
      const routeParams = {} as ListingSearchCityRouteParams;

      routeParams.listingTypes = listingRequest.listingTypeIds?.join(',');

      const listingTypeSlug = getListingTypeSlugById(
        listingRequest.listingTypeIds?.length === 1
          ? listingRequest.listingTypeIds[0]!
          : undefined,
        locale,
      );

      const citySlug =
        listingRequest.location?.citySlug || T('LBL_NETHERLANDS');

      routeParams.typeAndCity = `${listingTypeSlug}-${citySlug}`.toLowerCase();

      routeParams.searchview = listingRequest.searchview?.toString();

      routeParams.maxRent = listingRequest.maxRentalPriceId?.toString();

      routeParams.minSize = listingRequest.surfaceMinimumId?.toString();

      routeParams.radius = listingRequest.radiusId?.toString();

      routeParams.pageNo = listingRequest.pageNo?.toString();

      routeParams.sort = listingRequest.listingSortOptionId?.toString();

      routeParams.nwlat = listingRequest.lat_hi?.toString();
      routeParams.nwlng = listingRequest.lng_lo?.toString();
      routeParams.selat = listingRequest.lat_lo?.toString();
      routeParams.selng = listingRequest.lng_hi?.toString();

      routeParams.suitableForStatusIds =
        listingRequest.suitableForStatusIds?.join(',');

      if (listingRequest.streetSlug) {
        return ROUTES.ListingSearchStreet.url(
          { ...routeParams, street: listingRequest.streetSlug },
          locale,
        );
      }

      return ROUTES.ListingSearchCity.url(routeParams, locale);
    },
    [T, locale],
  );

  const updateUrl = React.useCallback(
    (listingRequest: ListingRequest): void => {
      const url = generateUrl(listingRequest);

      window.history.replaceState(
        {},
        '',
        locale === Locale.en ? `/en${url}` : url,
      );
    },
    [generateUrl, locale],
  );

  return { generateUrl, updateUrl };
};
